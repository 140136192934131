/*  Make table mobile responsive
    by shrinking it down to 
    one column. Use data-header
    to set column headers to
    cell "labels" */
@media (max-width: 767.97px) {
  .tradeTable {
    @apply bg-transparent;
  }
  .tradeTable tr {
    @apply flex
      flex-col
      mb-1
      border-t
      border-t-neutral-700;
  }
  .tradeTable tr.sub-row-parent {
    @apply border-t-2
      border-brand-primary-500;
  }
  /* move cell with expander ui to bottom of row */
  .tradeTable tr td[data-header='Expand Subtrades'] {
    @apply order-last
      text-left
      pt-[.1rem]
      pb-0.5
      pl-[7.5rem]
      flex;
  }
  .tradeTable tr td[data-header='Expand Subtrades']:before {
    @apply bg-transparent
      p-1;
  }
  .tradeTable tr.group-row {
    @apply mb-1;
  }
  .tradeTable tr.group-row td {
    @apply pt-2 text-left;
  }
  .tradeTable td {
    @apply relative
      pt-6
      px-2
      pb-2
      w-full
      text-right
      border-x
      border-b
      border-neutral-700;
  }
  .tradeTable thead {
    left: -9999px;
    @apply absolute
      invisible;
  }
  .tradeTable td:before {
    @apply absolute
      left-2
      top-0
      text-xs
      font-medium
      uppercase
      text-neutral-500
      dark:text-neutral-200;
  }
  .tradeTable td:before {
    @apply block
      absolute
      top-0
      left-0
      p-2
      content-[attr(data-header)];
  }
  /*  Hide the first cell on 
      rows with no subtrade */
  .tradeTable tr.no-sub-rows td:first-of-type {
    @apply hidden;
  }

  .tradeTable td.cell-no-data {
    @apply hidden;
  }
}
