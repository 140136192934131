@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  /* disables mobile pull-to-reload */
  overscroll-behavior: none;
}

/* ------
.set-vh 
Sets viewport height to fit within a browser's UI frame
Requires: useViewportHeight()
------ */
.set-vh {
  height: calc(var(--vh, 1vh) * 100) !important;
}

.overflow-clip-nosafari {
  overflow: clip;
}

/* Safari-specific override */
@supports (-webkit-backdrop-filter: none) {
  .overflow-clip-nosafari {
    overflow: unset !important;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  /* IE and Edge */
  -ms-overflow-style: none;
  /*Firefox*/
  scrollbar-width: none;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-show::-webkit-scrollbar {
  display: unset;
}

/* For IE, Edge and Firefox */
.scrollbar-show {
  -ms-overflow-style: unset;

  /* IE and Edge */

  scrollbar-width: unset;

  /* Firefox */
}

#userList > .infinite-scroll-component__outerdiv {
  width: 100%;
}

@layer components {
  h1 {
    @apply text-2xl
    md:text-3xl
    font-medium
    text-neutral-900
    dark:text-neutral-100;
  }

  h2 {
    @apply text-xl
    md:text-2xl
    font-medium
    text-neutral-900
    dark:text-neutral-100;
  }

  h3 {
    @apply text-lg
    md:text-xl
    font-medium
    text-neutral-900
    dark:text-neutral-100;
  }

  h4 {
    @apply text-base
    md:text-lg
    font-medium
    text-neutral-900
    dark:text-neutral-100;
  }

  h5 {
    @apply text-sm
    md:text-base
    font-medium
    text-neutral-900
    dark:text-neutral-100;
  }

  p,
  li {
    @apply text-sm
    md:text-base
    text-neutral-900
    dark:text-neutral-100;
  }

  button {
    white-space: nowrap;
  }

  .link {
    @apply text-blue-500
    dark:text-blue-400
    hover:text-blue-600
    dark:hover:text-blue-500
    hover:underline
    transition-all;
  }

  .input {
    @apply py-1.5
    px-2.5
    rounded-md
    text-black
    dark:text-white
    bg-white
    dark:bg-black
    border
    border-neutral-200
    dark:border-neutral-900;
  }

  .input:focus {
    @apply outline-none
    border-brand-primary-500
    dark:border-brand-primary-700;
  }

  .input:disabled {
    @apply bg-neutral-200
    dark:bg-neutral-800
    cursor-not-allowed;
  }

  /* Review these error styles once the functionality
  it in place. */

  .input-error {
    @apply border-red-500
    dark:border-red-500;
  }

  .input-error-message {
    @apply text-red-500
    dark:text-red-500
    text-sm
    font-bold;
  }

  .button-outline {
    @apply rounded-md
    hover:shadow-button
    dark:hover:shadow-dark-button
    py-1.5
    px-2.5
    border
    cursor-pointer
    transition-all
    font-bold
    text-sm
    bg-white
    dark:bg-black
    text-neutral-900
    dark:text-white
    hover:text-brand-primary-500
    border-brand-primary-600
    dark:border-brand-primary-700
    hover:border-brand-primary-500
    hover:dark:border-brand-primary-500;
  }

  .button-outline:disabled {
    @apply cursor-not-allowed
    text-black
    bg-neutral-100
    dark:text-white
    dark:bg-neutral-900
    hover:border-transparent
    hover:shadow-none;
  }

  .button-outline-neutral {
    @apply rounded-md
    hover:shadow-button
    py-1.5
    px-2.5
    border
    border-transparent
    cursor-pointer
    transition-all
    font-bold
    bg-transparent
    hover:border-neutral-200
    dark:hover:border-neutral-700
    text-neutral-600
    dark:text-white
    hover:text-neutral-900
    dark:hover:text-neutral-100;
  }

  .button-outline-neutral:disabled {
    @apply cursor-not-allowed
    bg-neutral-400
    dark:bg-neutral-700
    border-neutral-500
    dark:border-neutral-600
    text-black
    dark:text-white
    hover:shadow-none;
  }

  .button-fill {
    @apply border
    border-transparent
    rounded-md
    hover:shadow-button
    py-1.5
    px-2.5
    cursor-pointer
    transition-all
    font-bold
    bg-brand-primary-600
    dark:bg-brand-primary-700
    hover:bg-brand-primary-500
    dark:hover:bg-brand-primary-600
    text-sm
    text-white;
  }

  .button-fill:disabled {
    @apply cursor-not-allowed
    text-black
    bg-neutral-100
    dark:text-white
    dark:bg-neutral-900
    hover:border-transparent
    hover:shadow-none;
  }

  .button-fill-neutral {
    @apply border
    border-transparent
    rounded-md
    hover:shadow-button
    py-1.5
    px-2.5
    cursor-pointer
    transition-all
    font-bold
    bg-neutral-600
    dark:bg-neutral-700
    hover:bg-neutral-500
    dark:hover:bg-neutral-600
    text-sm
    text-white;
  }

  .button-x {
    @apply absolute
    top-1.5
    right-1.5
    text-black/75
    dark:text-white/75
    hover:text-red-500
    focus:text-red-500
    active:text-red-600;
  }
  .button-x svg {
    @apply w-5
    h-5;
  }

  .notif-bar-button {
    @apply px-1.5
    py-1
    md:px-2
    md:py-1.5
    text-xs
    lg:text-sm 
    border
    rounded
    cursor-pointer
    font-bold
    transition-all
    bg-blue-500
    text-white
    border-blue-600
    hover:bg-blue-600
    hover:shadow-button;
  }

  .notif-bar-dismiss {
    @apply px-1.5
    py-1
    md:px-2
    md:py-1.5
    text-xs
    lg:text-sm 
    border
    rounded
    cursor-pointer
    font-bold
    transition-all
    bg-neutral-500
    text-white
    border-neutral-600
    hover:bg-neutral-600;
  }

  .streamer-button {
    @apply px-3
    py-2
    border
    rounded
    cursor-pointer
    font-bold
    transition-all
    bg-green-500
    text-black
    dark:bg-green-700
    dark:text-white
    border-green-600
    hover:bg-green-600
    hover:shadow-button;
  }

  .button-confirm {
    @apply border
    border-transparent
    rounded-md
    hover:shadow-button
    py-2
    px-2.5
    cursor-pointer
    transition-all
    font-bold
    bg-green-600
    dark:bg-green-700
    hover:bg-green-500
    dark:hover:bg-green-600
    text-sm
    text-white;
  }

  .button-cancel {
    @apply border
    border-transparent
    rounded-md
    hover:shadow-button
    py-2
    px-2.5
    cursor-pointer
    transition-all
    font-bold
    bg-red-600
    dark:bg-red-800
    hover:bg-red-500
    dark:hover:bg-red-600
    text-sm
    text-white;
  }

  .button-cancel:disabled {
    @apply bg-neutral-400
    cursor-not-allowed
    hover:shadow-none;
  }

  .header-button {
    @apply flex
    items-center
    justify-center
    h-7
    w-7
    md:h-9
    md:w-fit;
    border-color: transparent !important;
  }

  .header-button-icon {
    @apply m-0
    md:mr-1
    md:ml-2
    text-base
    md:text-2xl
    leading-none;
  }

  .chat-nav-button {
    @apply whitespace-nowrap
    w-full
    !rounded-none
    md:!rounded-md
    !border-0
    md:!border;
  }

  .chat-nav-button-active {
    @apply button-fill
    cursor-default
    hover:bg-brand-primary-600
    dark:hover:bg-brand-primary-800;
    box-shadow: none !important;
  }

  .chat-nav-button-inactive {
    @apply button-outline
    cursor-default;
    box-shadow: none !important;
  }

  .mod-button {
    @apply flex
    items-center
    justify-around
    bg-neutral-50
    dark:bg-neutral-800
    border
    border-neutral-200
    hover:border-neutral-300
    dark:border-neutral-700
    p-1
    text-white
    cursor-pointer
    hover:bg-neutral-200
    dark:hover:bg-neutral-700
    uppercase
    font-bold
    rounded-full;
  }

  .modal-dialogue-panel {
    @apply relative
    transform
    overflow-hidden
    rounded-xl
    bg-white
    dark:bg-neutral-900
    text-left
    shadow-xl
    transition-all
    w-full
    my-auto
    sm:mx-2
    sm:my-8
    sm:max-w-3xl;
  }

  .modal-dialogue-panel-inner-wrapper {
    @apply bg-white
    dark:bg-neutral-900
    px-4
    pt-5
    pb-4
    sm:p-6
    sm:pb-4;
  }

  .modal-dialogue-panel-footer {
    @apply bg-neutral-50
    dark:bg-neutral-800
    px-4
    py-3
    sm:flex
    sm:flex-row-reverse
    sm:px-6
    border-t
    border-neutral-100
    dark:border-neutral-700;
  }

  .modal-button-cancel {
    @apply mt-3
    inline-flex
    w-full
    justify-center
    rounded-md
    border
    border-neutral-300
    dark:border-neutral-600
    bg-white
    dark:bg-neutral-700
    px-4
    py-2
    text-base
    font-medium
    text-neutral-700
    dark:text-neutral-100
    shadow-sm
    hover:bg-neutral-50
    dark:hover:bg-neutral-600
    dark:hover:border-neutral-500
    focus:outline-none
    focus:ring-2
    focus:ring-brand-primary-500
    focus:ring-offset-2
    dark:focus:ring-offset-neutral-800
    sm:mt-0
    sm:ml-3
    sm:w-auto
    sm:text-sm;
  }

  .modal-confirm-button {
    @apply inline-flex
    w-full
    justify-center
    rounded-md
    border
    border-transparent
    bg-red-600
    px-4
    py-2
    text-base
    font-medium
    text-white
    shadow-sm
    hover:bg-red-700
    focus:outline-none
    focus:ring-2
    focus:ring-red-500
    focus:ring-offset-2
    dark:focus:ring-offset-neutral-800
    sm:ml-3
    sm:w-auto
    sm:text-sm;
  }

  .splash-container {
    @apply w-screen
    mx-auto
    py-2
    sm:px-8
    text-black
    dark:text-neutral-100
    bg-white/80
    dark:bg-neutral-900/80
    sm:w-[30rem]
    sm:shadow
    sm:rounded;
  }

  .bg-primary-gradient {
    @apply bg-gradient-to-r
    from-brand-primary-100
    to-brand-primary-500
    dark:from-brand-primary-900
    dark:to-brand-primary-700;
  }

  .streamer-pic {
    @apply w-12
    lg:w-16
    h-12
    lg:h-16
    object-cover
    object-top
    rounded-full
    border-2;
  }
  /* input `range` styles for the volume and tracking slider on the video player tracking coming later with VODs */

  /* Range Reset */
  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 5rem;
  }

  /* Firefox Range Thumbnail Border Reset */
  input[type='range']::-moz-range-thumb {
    border: none;
  }

  /* Removes default focus */
  input[type='range']:focus {
    outline: none;
  }

  /* track slider */
  input[type='range']::-webkit-slider-runnable-track {
    background-color: #fff9;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  input[type='range']::-moz-range-track {
    background-color: #fff9;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;

    /* reset */
    appearance: none;
    margin-top: -2px;
    background-color: #fff;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 100%;
  }

  input[type='range']::-moz-range-thumb {
    background-color: #fff;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 100%;
  }

  /* slider thumb hover */
  input[type='range']:hover::-webkit-slider-thumb,
  input[type='range']:hover::-moz-range-thumb {
    @apply bg-brand-primary-500;
  }

  /* slider thumb focus */
  input[type='range']:focus::-webkit-slider-thumb,
  input[type='range']:focus::-moz-range-thumb {
    outline: 1px solid #fffc;
    outline-offset: 0.125rem;
    @apply bg-brand-primary-600;
  }
}

@keyframes rainbow {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 200%;
  }
}

@keyframes breathe {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.animate-boom {
  transition: 0.2s;
  background: linear-gradient(
    285deg,
    #48ff00dd,
    #00cc00dd,
    #009900dd,
    #48ff00dd
  );
  background-size: 200%;
  animation:
    rainbow 1.618s ease infinite,
    breathe 1.618s ease infinite;
  box-shadow: 0 2px 6px #000b;
  color: rgb(0, 0, 0);
}
.animate-boom:hover {
  color: rgb(0, 0, 0);
  box-shadow: 0 2px 6px #000f;
  filter: saturate(200%);
  animation:
    rainbow 1.618s ease infinite,
    breathe 1.618s ease-in-out 1 0.8s;
  transform: scale(1.1);
}
