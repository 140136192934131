.vjs-moal.video-js .vjs-control-bar {
  /* reset bg */
  @apply bg-transparent;
}

.vjs-moal.video-js .vjs-control-bar {
  @apply bg-black/80 h-14;
}

.vjs-moal.video-js .vjs-control-bar .vjs-button > .vjs-icon-placeholder:before {
  font-size: 2.5em;
  line-height: 2.25;
}

.vjs-moal.video-js .vjs-control-bar .vjs-control:focus {
  text-shadow: 0em 0em 2em rgba(255, 255, 255, 0.75);
}

.vjs-moal.video-js .vjs-control-bar .vjs-live-control {
  font-size: 1.3em;
  margin-top: 8px;
  @apply invisible;
}

.vjs-moal.video-js .vjs-control-bar .vjs-vol-0 {
  @apply text-red-500;
}

.vjs-moal.video-js .vjs-control-bar .vjs-volume-bar {
  margin-top: 26px;
}

.vjs-moal.video-js .vjs-control-bar .vjs-menu {
  left: -6em;
  margin-bottom: 3em;
}

.vjs-moal.video-js .vjs-control-bar .vjs-menu .vjs-menu-content {
  @apply bg-black/50;
  bottom: 2.6em;
}

.vjs-moal.video-js .vjs-control-bar .vjs-menu .vjs-menu-item {
  font-size: 1.5em;
  @apply font-medium text-neutral-300;
}

.vjs-moal.video-js .vjs-control-bar .vjs-menu .vjs-menu-item:hover {
  @apply bg-neutral-300/25;
}

.vjs-moal.video-js .vjs-control-bar .vjs-menu .vjs-menu-item.vjs-selected {
  @apply bg-neutral-300 text-neutral-900;
}
