.badge {
  @apply absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 duration-200 shadow-lg;
}

.badge .circle {
  @apply absolute w-1.5 h-1.5 bg-white rounded-full;
}

.badge .circle[data='animate'] {
  @apply animate-announce-ping bg-blend-multiply bg-red-500;
}
