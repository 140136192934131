/* This resolves a layout problem with the Quill JS toolbar overlapping content below it. */
.quill {
  padding-top: 41px;
}
.ql-toolbar {
  margin-top: -41px;
}

/* This inverts the colors of the Quill JS container and editor for dark mode. */
@media (prefers-color-scheme: dark) {
  .quill {
    filter: invert(1);
  }
  .ql-editor {
    filter: invert(1);
  }
}
