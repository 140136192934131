.wrapper {
  @apply flex flex-col xl:flex-row gap-2 text-sm border-t rounded-b-md p-2 dark:border-neutral-700 bg-neutral-200 dark:bg-neutral-800;
}

label.range {
  @apply flex items-center;
}

.range p {
  @apply border border-neutral-400 dark:border-neutral-700 dark:bg-neutral-700 bg-neutral-300 h-full my-auto dark:text-neutral-100 rounded-l border-r-0 p-1 px-2 text-sm;
}

.range input {
  @apply border border-neutral-400 dark:bg-neutral-900 h-full my-auto dark:text-neutral-100 rounded-r p-1 px-2 text-sm;
  color-scheme: light;
}

@preferred-color-scheme dark {
  .rangeLabel input {
    color-scheme: dark;
  }
}

label.showAll {
  @apply cursor-pointer flex w-fit items-center justify-between px-2 py-1 gap-1.5 rounded border transition-all;
}
